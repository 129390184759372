import styled from 'styled-components';
import {contentHeight, media} from '../../../mixins';

interface LayoutProps {
  sidebarExpand: boolean;
}

export const Layout = styled.div<LayoutProps>`
  height: 100vh;
  display: grid;
  grid-template-columns: ${({sidebarExpand, theme}) =>
      sidebarExpand ? theme.sidebar.expanded : theme.sidebar.collapsed} auto;
  grid-template-rows: ${({theme}) => theme.header.height} ${contentHeight()};
  grid-template-areas:
    'sidebar header'
    'sidebar content';

  ${media.tablet`
    grid-template-columns: 0 auto;
  `};
`;

export default Layout;
