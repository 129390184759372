import React from 'react';
import {DefaultSkeleton, SkeletonHeading} from './styles/Skeletons';

interface TitleSkeletonProps {
  small?: boolean;
}

export function TitleSkeleton({small, ...rest}: TitleSkeletonProps) {
  return <SkeletonHeading small={small} {...rest} />;
}

export function Skeleton({...props}) {
  return <DefaultSkeleton {...props} />;
}
