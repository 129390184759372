import styled, {keyframes} from 'styled-components';

const slideInFromTop = keyframes`
  0% {
    transform: translateY(-100%)
  }
  100% {
    transform: translateY(0)
  }
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${({theme}) => theme.color.darkBlue};
  cursor: pointer;
  display: block;
  font-size: 3rem;
  padding: 0;
  position: absolute;
  top: 1.6rem;
  right: 2rem;

  svg {
    transition: all 0.2s ease-out;

    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const StyledToast = styled.div`
  background: ${({theme}) => theme.color.lightBlue};
  color: ${({theme}) => theme.color.white};
  display: flex;
  width: 100%;
  align-items: center;
  padding: 2rem;
  animation: ${slideInFromTop} 0.3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;

  & + & {
    margin-top: 2rem;
  }

  &.error {
    background: #bf3405;
    z-index: 50;

    ${CloseButton} {
      color: ${({theme}) => theme.color.darkOrange};
    }
  }
`;

export const Message = styled.p`
  margin: 0;
  padding-right: 4rem;
`;
