import styled, {keyframes, css} from 'styled-components';

const skeletonAnimation = keyframes`
  0% {
    opacity: 0.45;
  }
  100% {
    opacity: 0.9;
  }
`;

const skeletonStyles = css`
  position: relative;
  width: 100%;
  height: 2.3rem;

  &:after {
    content: '';
    background: #d5dde4;
    position: absolute;
    border-radius: 0.3rem;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation: ${skeletonAnimation} 0.8s linear infinite alternate;
  }
`;

export const DefaultSkeleton = styled.div`
  ${skeletonStyles}
`;

export const SkeletonHeading = styled.div`
  ${skeletonStyles}
  max-width: ${({small}: TitleSkeletonProps) => (small ? '6rem' : '12rem')};
  min-width: 6rem;
  height: 2.3rem;
`;
