import styled from 'styled-components';

import {media} from '../../../mixins';

interface StackProps {
  wrap?: boolean;
  align?: string;
  gutter?: boolean;
}

interface StackItemProps {
  noShrink?: boolean;
}

const alignItems = ({align}: StackProps): string => {
  switch (align) {
    case 'center':
      return 'center';
    default:
      return 'flex-start';
  }
};

const itemFlex = ({noShrink}: StackItemProps): string => {
  if (noShrink) {
    return '1 0 auto';
  }
  return '1 1 0';
};

export const Item = styled.div<StackItemProps>`
  flex: ${itemFlex};

  ${media.phone`
    flex: 0 1 auto;
  `};
`;

export const Stack = styled.section<StackProps>`
  display: flex;
  flex-wrap: ${props => (props.wrap ? 'wrap' : 'nowrap')};
  align-items: ${alignItems};

  ${Item} {
    margin-right: ${props => (props.gutter ? '5rem' : '0')};

    &:last-of-type {
      margin-right: 0;
    }
  }

  ${media.tablet`
    flex-wrap: wrap;

    ${Item} {
      margin-right: 0;
    }
  `};
`;
