export {useProtection} from './hook';
export {useDoorkeeper} from './doorkeeper';

export {context} from './context';
export {AuthProvider} from './provider';
export {Provider as OAuthProvider} from './oauth';
export {AuthEvent} from './service';
export {Record} from './storage';

/*
 * TODO If we are going to extract auth module into its own npm module, this is
 *  the initialization code that should not live within the module.
 */

// @ts-ignore
import config from 'config';

import {Spurtli, Strava} from 'utilities/provider';

import {create as createStorage} from './storage';
import {Provider} from './oauth';
import {Service as AuthService} from './service';

export const storage = createStorage();
export const primaryProvider = {
  authorizationUrl: Spurtli.authorizationUrl,
  tokenUrl: Spurtli.tokenUrl,
  revokeUrl: Spurtli.revokeUrl,
  clientId: config.api.clientId,
};

const authorizationProvider = new Map<string, Provider>([
  [
    'strava',
    {
      authorizationUrl: Strava.authorizationUrl,
      scopes: Strava.defaultScopes,
    },
  ],
]);

export const service = new AuthService(
  primaryProvider,
  authorizationProvider,
  storage
);
