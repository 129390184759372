import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import {Icon} from 'components/ultra';
import {useProtection, service as auth} from 'utilities/auth';
import {useReactRouter} from 'utilities/react-router';
import {useUser} from 'utilities/user';

import {
  CartLink,
  CartCounter,
  HeaderWrapper,
  Nav,
  MainNav,
  NavToggle,
  Logo,
  InternalLink,
  ExternalLink,
  UserNav,
} from './styles';
import LogoSVG from './logo.svg';

interface Item {
  title: string;
  to: {pathname: string} | string;
  requireAuth?: boolean;
  button?: boolean;
  icon?: any;
  external?: boolean;
}

export function Header({cart}: any) {
  const [isNavActive, setNavActive] = useState(false);
  const {location} = useReactRouter();
  const {isAuthenticated} = useProtection();
  const user = useUser();

  const mainNav = [
    {
      title: 'About',
      to: {pathname: '/about'},
    },
    {
      title: 'Pricing',
      to: {pathname: '/pricing'},
    },
    {
      button: true,
      icon: <Icon icon="add" />,
      title: 'Memento',
      to: '/poster/new',
    },
  ];

  const userNav = [
    {
      icon: <Icon icon="user" />,
      requireAuth: true,
      title: user?.firstname,
      to: '/dashboard',
    },
    {
      external: false,
      requireAuth: false,
      title: 'Sign up',
      to: '/sign-up',
    },
    {
      external: true,
      requireAuth: false,
      title: 'Sign in',
      to: auth.currentAuthorizationProvider.authorizationUrl,
    },
    {
      icon: <Icon icon="cart" />,
      requireAuth: true,
      title:
        cart?.count >= 1 ? (
          <CartLink>
            Cart <CartCounter>{cart?.count}</CartCounter>
          </CartLink>
        ) : (
          <span>Cart</span>
        ),
      to: '/cart',
    },
  ];

  function renderNavItem(item: Item) {
    const {requireAuth} = item;

    if (requireAuth === false && isAuthenticated) {
      return null;
    }

    if (requireAuth === true && !isAuthenticated) {
      return null;
    }

    return renderRegularNavItem(item);
  }

  function renderRegularNavItem(item: Item) {
    const {title, to, icon = null, external = false, button = false} = item;

    const Link: any = external ? ExternalLink : InternalLink;
    const titleText = title;

    if (!to) {
      return null;
    }

    const props: any = {
      className: button ? 'button' : '',
    };

    if (external) {
      props.href = to;
    } else {
      props.to = to;
    }

    return (
      <li key={titleText}>
        <Link {...props}>
          {icon} {titleText}
        </Link>
      </li>
    );
  }

  function toggleNav() {
    setNavActive(!isNavActive);
  }

  function renderNav() {
    if (location.pathname === '/user/import') {
      return null;
    }

    return (
      <>
        <NavToggle onClick={toggleNav}>
          <Icon icon="menu" />
        </NavToggle>
        <Nav active={isNavActive}>
          <MainNav>{mainNav.map(item => renderNavItem(item))}</MainNav>
          <UserNav>{userNav.map(item => renderNavItem(item))}</UserNav>
        </Nav>
      </>
    );
  }

  return (
    <HeaderWrapper>
      <Logo>
        <Link to="/">
          <img src={LogoSVG} alt="Logo" />
        </Link>
      </Logo>
      {renderNav()}
    </HeaderWrapper>
  );
}
