import React from 'react';
import {IconType} from 'react-icons';
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaPlus,
  FaShoppingCart,
  FaSave,
  FaTimes,
  FaCreditCard,
  FaCartPlus,
  FaRegTrashAlt,
  FaSignOutAlt,
  FaUserAlt,
  FaBars,
} from 'react-icons/fa';
import {MdFileDownload} from 'react-icons/md';

const icons: {[key: string]: IconType} = {
  add: FaPlus,
  cart: FaShoppingCart,
  cartAdd: FaCartPlus,
  cartAddFilled: FaCartPlus,
  close: FaTimes,
  creditCard: FaCreditCard,
  delete: FaRegTrashAlt,
  download: MdFileDownload,
  facebook: FaFacebookF,
  instagram: FaInstagram,
  menu: FaBars,
  save: FaSave,
  signOut: FaSignOutAlt,
  twitter: FaTwitter,
  user: FaUserAlt,
};

type IconCollection = keyof typeof icons;
interface ComposedProps {
  icon: IconCollection;
}

export function Icon({icon, ...props}: ComposedProps) {
  const Icon = icons[icon];
  return <Icon />;
}
