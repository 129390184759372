import * as Types from '../../../../../gql/types';

import {gql} from '@apollo/client';
import {UserFragmentDoc} from '../../../../../gql/fragments/user';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CompleteSignupMutationVariables = Types.Exact<{
  user: Types.UserInput;
}>;

export type CompleteSignupMutation = {
  __typename?: 'Mutation';
  saveUser?: {
    __typename?: 'SaveUserPayload';
    me: {
      __typename?: 'User';
      id: string;
      careOf?: string | null;
      city?: string | null;
      country?: string | null;
      email?: string | null;
      emailConfirmed: boolean;
      emailConfirmationPending: boolean;
      firstname?: string | null;
      lastname?: string | null;
      name?: string | null;
      state?: string | null;
      street?: string | null;
      unconfirmedEmail?: string | null;
      units: Types.Unit;
      username?: string | null;
      zip?: string | null;
    };
    errors: Array<{__typename?: 'Error'; message: string}>;
  } | null;
};

export const CompleteSignupDocument = gql`
  mutation completeSignup($user: UserInput!) {
    saveUser(user: $user) {
      me {
        id
        ...user
      }
      errors {
        message
      }
    }
  }
  ${UserFragmentDoc}
`;
export type CompleteSignupMutationFn = ApolloReactCommon.MutationFunction<
  CompleteSignupMutation,
  CompleteSignupMutationVariables
>;

/**
 * __useCompleteSignupMutation__
 *
 * To run a mutation, you first call `useCompleteSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeSignupMutation, { data, loading, error }] = useCompleteSignupMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCompleteSignupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CompleteSignupMutation,
    CompleteSignupMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return ApolloReactHooks.useMutation<
    CompleteSignupMutation,
    CompleteSignupMutationVariables
  >(CompleteSignupDocument, options);
}
export type CompleteSignupMutationHookResult = ReturnType<
  typeof useCompleteSignupMutation
>;
export type CompleteSignupMutationResult =
  ApolloReactCommon.MutationResult<CompleteSignupMutation>;
export type CompleteSignupMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CompleteSignupMutation,
    CompleteSignupMutationVariables
  >;
