import development from './development';
import production from './production';

type ConfigApi = {
  url: string;
  clientId: string;
};

type ConfigApp = {
  url: string;
};

type ConfigStrava = {
  clientId: number;
};

type Config = {
  api: ConfigApi;
  app: ConfigApp;
  strava: ConfigStrava;
};

let config: Config = development;

if (process.env.NODE_ENV === 'production') {
  config = production;
}

export default config as Config;
