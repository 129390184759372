import React, {ReactNode, useEffect} from 'react';

import {Button} from '../Button';
import {Heading} from '../Heading';
import {Icon} from '../Icon';
import {Action} from '../types';

import {StyledBanner, Actions, Message, CloseButton} from './styles/Banner';

type Status = 'info' | 'error';
interface Props {
  autoClose?: boolean;
  children: ReactNode;
  primaryAction?: Action;
  status?: Status;
  title?: string;
  onDismiss?(): void;
}

const TIMER_DURATION = 5000;

export function Banner({
  autoClose = false,
  children,
  primaryAction,
  status = 'info',
  title,
  onDismiss,
}: Props) {
  let timer: number | null;

  useEffect(() => {
    if (autoClose) {
      timer = window.setTimeout(onDismiss, TIMER_DURATION);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const closeIcon = onDismiss && (
    <CloseButton onClick={onDismiss}>
      <Icon icon="close" />
    </CloseButton>
  );

  const primaryActionMarkup = primaryAction && (
    <Button size="small" severity="inverse" onClick={primaryAction.onAction}>
      {primaryAction.label}
    </Button>
  );

  return (
    <StyledBanner className={status}>
      <Heading element="h4">{title}</Heading>
      <Message>{children}</Message>
      <Actions>{primaryActionMarkup}</Actions>
      {closeIcon}
    </StyledBanner>
  );
}
