import * as Types from '../types';

import {gql} from '@apollo/client';
export type UserFragment = {
  __typename?: 'User';
  id: string;
  careOf?: string | null;
  city?: string | null;
  country?: string | null;
  email?: string | null;
  emailConfirmed: boolean;
  emailConfirmationPending: boolean;
  firstname?: string | null;
  lastname?: string | null;
  name?: string | null;
  state?: string | null;
  street?: string | null;
  unconfirmedEmail?: string | null;
  units: Types.Unit;
  username?: string | null;
  zip?: string | null;
};

export const UserFragmentDoc = gql`
  fragment user on User {
    id
    careOf
    city
    country
    email
    emailConfirmed
    emailConfirmationPending
    firstname
    lastname
    name
    state
    street
    unconfirmedEmail
    units
    username
    zip
  }
`;
