import {useContext, useEffect} from 'react';
import {useReactRouter} from 'utilities/react-router';
import {context as authContext} from './context';

export function useDoorkeeper(targetPath: string = '/about') {
  const {history, location} = useReactRouter();
  const {isAuthenticated} = useContext(authContext);

  useEffect(() => {
    if (!isAuthenticated) {
      history.push(targetPath);
    }
  }, [location.pathname, isAuthenticated, history, targetPath]);
}
