import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
  concat,
  split,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
// import {IntrospectionFragmentMatcher} from 'apollo-cache-inmemory';
// @ts-ignore
import ActionCable from 'actioncable';
// @ts-ignore
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
// @ts-ignore
import config from 'config';
// import introspectionQueryResultData from 'gql/fragment-introspection.json';
import {service as auth, Record} from 'utilities/auth';

// const fragmentMatcher = new IntrospectionFragmentMatcher({
//   introspectionQueryResultData,
// });

const manualPossibleTypes = {
  Reference: ['Poster'],
  PaymentDetails: ['CreditCard'],
};

export const cache = new InMemoryCache({
  possibleTypes: manualPossibleTypes,
})
  // @ts-ignore
  .restore(window.__APOLLO_STATE__);

const httpLink = createHttpLink({uri: `${config.api.url}/graphql`});

const cable = ActionCable.createConsumer(`${config.api.url}/cable`);
const actionCableLink = new ActionCableLink({cable});

// @ts-ignore
const hasSubscriptionOperation = ({query: {definitions}}) => {
  // @ts-ignore
  return definitions.some(({kind, operation}) => {
    return kind === 'OperationDefinition' && operation === 'subscription';
  });
};

/*
 * We do not send any requests before we have authenticated the current user.
 *
 * `ensureAuthenticated` is "blocking" all calls to the backend until we have a
 * valid token. In order to not hold back forever, we have a back-off strategy.
 * After n attempts over time t, we just stop trying.
 */
async function ensureAuthenticated(failFast: boolean = false): Promise<Record> {
  if (auth.hasValidToken()) {
    return auth.getToken();
  }

  if (auth.hasToken()) {
    const token = await auth.refreshToken();
    if (auth.hasValidToken()) {
      return token;
    }
  }

  if (failFast) {
    return null;
  }

  return new Promise((resolve, reject) => {
    const max = 40;
    let cnt = 1;
    let int = setInterval(() => {
      if (auth.hasValidToken()) {
        int && clearInterval(int);
        return resolve(auth.getToken());
      }

      if (++cnt > max) {
        int && clearInterval(int);
        return reject(
          'Cannot authenticate, please provide an authorization code or an access token.'
        );
      }
    }, 100);
  });
}

const subscriptionAuthLink = setContext(async () => {
  const token = await ensureAuthenticated();
  // FIXME cannot set authorization header, we have to reopen connection
  cable.url = ActionCable.createWebSocketURL(
    `${config.api.url}/cable?access_token=${token.accessToken}`
  );
  cable.connection.reopen();
});

const withToken = setContext(async () => {
  const token = await ensureAuthenticated(true);
  if (!token) {
    return;
  }

  return {
    headers: {
      authorization: `Bearer ${token.accessToken}`,
    },
  };
});

const authError = onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({message, locations, path}) =>
      // eslint-disable-next-line no-console
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) {
    auth.revokeToken();
  }
});
const httpAuthLink = withToken.concat(authError);

export const client = new ApolloClient({
  cache,
  link: ApolloLink.from([
    split(
      hasSubscriptionOperation,
      concat(subscriptionAuthLink, actionCableLink),
      concat(httpAuthLink, httpLink)
    ),
  ]),
});
