import styled from 'styled-components';

export const Overlay = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const ModalContainer = styled.div`
  margin-top: -10%;
  max-width: 64rem;
  width: 100vw;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CloseButton = styled.button`
  background: ${({theme}) => theme.color.darkGray};
  border: none;
  color: ${({theme}) => theme.color.white};
  cursor: pointer;
  display: block;
  font-size: 3rem;
  height: 3.5rem;
  margin-top: 0.2rem;
  min-width: 3.5rem;

  &:hover {
    background: ${({theme}) => theme.color.orange};
  }
`;

export const Content = styled.div`
  border-top: 1px solid ${({theme}) => theme.color.gray};
  margin: 1.2rem 0;
  padding: 0.8rem 0;
`;

export const Buttons = styled.div`
  align-items: center;
  border-top: 1px solid ${({theme}) => theme.color.gray};
  display: flex;
  justify-content: flex-end;

  > *:nth-child(2) {
    margin-left: 1.6rem;
  }
`;
