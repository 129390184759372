// @ts-ignore
import config from 'config';

export const defaultScopes = [
  'read',
  'read_all',
  'profile:read_all',
  'activity:read',
  'activity:read_all',
];

export const authorizationUrl = `
https://www.strava.com/oauth/authorize
?client_id=${config.strava.clientId}
&response_type=code
&redirect_uri=${config.app.url}/import
&scope=${defaultScopes.join(',')}
&approval_prompt=auto
`;
