import React, {useState} from 'react';

import {StyledRadioButtons} from './styles/RadioButtons';
import {Option} from '../types';

interface Props {
  options: Option[];
  selectedOption: string;
  onChange(value: string): void;
}

export function RadioButtons({options, selectedOption, onChange}: Props) {
  const [selected, setSelected] = useState(selectedOption);

  const radioButtons = options.map(option => (
    <li key={option.value}>
      <label>
        <input
          type="radio"
          name="react-tips"
          value="option2"
          checked={selected === option.value}
          className="form-check-input"
          onChange={() => {
            onChange(option.value);
            setSelected(option.value);
          }}
        />{' '}
        <div className="box">
          <div className="selected"></div>
        </div>
        {option.label}
      </label>
    </li>
  ));
  return <StyledRadioButtons>{radioButtons}</StyledRadioButtons>;
}
