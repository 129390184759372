import React, {useEffect} from 'react';

import {Icon} from 'components/ultra';
import {StyledToast, Message, CloseButton} from './styles/Toast';

interface Props {
  autoClose?: boolean;
  message: string;
  status?: 'success' | 'error';
  onDismiss?(): void;
}

const TIMER_DURATION = 5000;

export function Toast({
  autoClose = true,
  message,
  status = 'success',
  onDismiss,
}: Props) {
  let timer: number | null;

  useEffect(() => {
    if (autoClose) {
      timer = window.setTimeout(onDismiss, TIMER_DURATION);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const closeIcon = onDismiss && (
    <CloseButton onClick={onDismiss}>
      <Icon icon="close" />
    </CloseButton>
  );

  return (
    <StyledToast className={status}>
      <Message>{message}</Message>
      {closeIcon}
    </StyledToast>
  );
}
