export default {
  api: {
    url: 'https://api.spurtli.com',
    clientId:
      '832f500f4f139c7a689b0ead1f4ad881a694b3cf36d9f88fc2bc3be8c89784f1',
  },
  strava: {
    clientId: 19580,
  },
  stripe: {
    apiKey: 'pk_test_xd5TQcIlBNhp7e24QCmoa56F',
  },
  app: {
    url: 'https://www.spurtli.com',
  },
};
