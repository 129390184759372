import styled, {keyframes} from 'styled-components';

const spin = keyframes`
  0%, 100% {
    transform:translate(0);
  }
  25% {
    transform:translate(160%);
  }
  50% {
    transform:translate(160%, 160%);
  }
  75% {
    transform:translate(0, 160%);
  }
`;

export const Loader = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;

  hr {
    border: 0;
    margin: 0;
    width: 40%;
    height: 40%;
    position: absolute;
    border-radius: 50%;
    animation: ${spin} 2s ease infinite;

    &:first-child {
      background: #f64a21;
      animation-delay: -1.5s;
    }

    &:nth-child(2) {
      background: #395b61;
      animation-delay: -1s;
    }

    &:nth-child(3) {
      background: #7ab1bc;
      animation-delay: -0.5s;
    }

    &:last-child {
      background: #212b35;
    }
  }
`;
