import styled from 'styled-components';
import {media} from 'mixins';
import {textGradientOrange} from '../Heading';

export const H4 = styled.h4``;

export const H3 = styled.h3`
  &:not(:first-child) {
    margin-top: 3.2rem;
  }

  + ${H4} {
    margin-top: 1.6rem;
  }
`;

export const H2 = styled.h2`
  color: ${({theme}) => theme.color.darkGray};
  font-size: 3.2rem;
  line-height: 1.25;
  font-weight: 900;

  ${media.tablet`
    font-size: 2.5rem;
  `};

  + ${H3} {
    margin-top: 1.2rem;
  }

  ${props => props.gradient && textGradientOrange}
`;

export const H1 = styled.h1`
  color: ${({theme}) => theme.color.orange};
  font-weight: 900;
  font-size: 7rem;

  ${media.tablet`
    font-size: 4rem;
    line-height: 1;
  `};

  ${media.phone`
    margin-bottom: .5rem;
  `};

  + ${H2} {
    margin-top: 0;
    font-weight: 700;
  }

  ${props => props.gradient && textGradientOrange}
`;
