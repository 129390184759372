import React from 'react';

import {Loader} from './styles/Preloader';

export function Preloader() {
  return (
    <Loader>
      <hr />
      <hr />
      <hr />
      <hr />
    </Loader>
  );
}
