import styled from 'styled-components';

import {Props} from '../Box';
import {StyledBanner} from '../../Banner/styles/Banner';

export const StyledBox = styled('div')<Props>`
  background: white;
  border-radius: 0.3rem;
  margin-top: 2rem;
  padding: 2.8rem 2rem 2.6rem;
  position: relative;

  &:first-child {
    margin-top: 0;
  }
  /* ${StyledBanner} + & {
    margin-top: 3.2rem;
  } */
`;
