import styled from 'styled-components';

export const Form = styled.form`
  position: relative;
`;

export const FormFields = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Input = styled.input`
  background-color: transparent;
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 0.5rem 0rem 0.8rem;
  border: none;
  font-size: 2rem;

  &:focus {
    outline: none;

    & + div:after {
      border-color: ${props => props.theme.color.lightBlue};
      transform: scale(1, 1);
    }
  }
`;

export const Label = styled.label`
  color: ${props => props.theme.color.darkBlue};
  text-transform: uppercase;
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  /*
  & + ${Input} {
    margin-top: 1rem;
  } */
`;

export const FormInputStyles = styled.section<{fullwidth: boolean}>`
  flex: 0 1 ${(props: any) => (props.fullwidth ? '100%' : '48%')};
  margin-bottom: 1.5rem;

  div {
    position: relative;

    &:after,
    &:before {
      position: absolute;
      content: '';
      border-bottom: 2px solid ${props => props.theme.color.darkBlue};
      display: block;
      bottom: 0;
      width: 100%;
    }

    &:after {
      transform: scale(0, 1);
      transition: 0.3s transform ease-in;
    }
  }
`;

export const FormTextareaStyles = styled.div``;

export const Textarea = styled.textarea`
  background: transparent;
  border: 0;
  width: 100%;
  resize: vertical;
  height: 10rem;
`;
