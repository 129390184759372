export default {
  api: {
    //url: 'http://localhost:3000',
    //clientId: 'ec9188c23d23b7c2f6275aa04b909ac7ed1c1bebc6145097c208d363a4c43b03'
    url: 'https://api.spurtli.com',
    clientId:
      '832f500f4f139c7a689b0ead1f4ad881a694b3cf36d9f88fc2bc3be8c89784f1',
  },
  strava: {
    //clientId: 19733,
    clientId: 19580,
  },
  stripe: {
    apiKey: 'pk_test_xd5TQcIlBNhp7e24QCmoa56F',
  },
  app: {
    url: 'http://localhost:8080',
  },
};
