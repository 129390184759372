import React from 'react';
import moment from 'moment';

import {Label} from 'components/ultra';
import {Button} from '../';
import {DatePresets} from './styles/TimeRange';

interface Props {
  onChange(dates: Record<string, string>): void;
  range: number;
  active: string;
}

export function TimeRange({onChange, range, active}: Props) {
  const activeButton = moment(active).year();

  const buttons = [...Array(range).keys()].map(index => {
    const currentYear = moment().year() - index;

    return (
      <Button
        key={currentYear}
        onClick={() => {
          onChange({
            activitiesStartDate: moment(`${currentYear}-01-01`)
              .utc()
              .startOf('day')
              .toISOString(),
            activitiesEndDate: moment(`${currentYear}-12-31`)
              .utc()
              .endOf('day')
              .toISOString(),
          });
        }}
        size="small"
        severity={(currentYear === activeButton && 'inverse') || undefined}
      >
        {currentYear}
      </Button>
    );
  });

  return (
    <>
      <Label>Time range</Label>
      <DatePresets>{buttons}</DatePresets>
    </>
  );
}
