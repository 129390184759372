import React from 'react';

import {FormInputStyles, Label, Input, Textarea} from './styles/Form';
export {Form, FormFields, Label} from './styles/Form';

interface AdditionalFormProps {
  label: string;
  fullwidth?: boolean;
}

export const FormInput = ({
  label,
  fullwidth,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement> & AdditionalFormProps) => (
  <FormInputStyles fullwidth={fullwidth}>
    <Label htmlFor={props.name}>{label}</Label>
    <Input type="text" {...props} />
    <div />
  </FormInputStyles>
);

export const FormTextarea = ({
  label,
  fullwidth,
  ...props
}: React.TextareaHTMLAttributes<HTMLTextAreaElement> & AdditionalFormProps) => (
  <FormInputStyles fullwidth={fullwidth}>
    <Label htmlFor={props.name}>{label}</Label>
    <Textarea {...props} />
    <div />
  </FormInputStyles>
);
