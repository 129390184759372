import React, {ReactChild} from 'react';

import {StyledBox} from './styles/Box';

export interface Props {
  children: ReactChild[];
  spaced?: boolean;
}

export function Box({children, spaced = true}: Props) {
  return <StyledBox spaced={spaced}>{children}</StyledBox>;
}
