import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';
import Loader from './loader.svg';

import {Props} from '../Button';

const ButtonBase = css`
  background: transparent;
  border: 1px solid ${({theme}) => theme.color.orange};
  color: ${({theme}) => theme.color.orange};
  display: ${(props: Props) => (props.fullwidth ? 'block' : 'inline-block')};
  font-size: 1.7rem;
  font-weight: 700;
  padding: 1.7rem 2rem 1.6rem;
  text-decoration: none;
  transition: color 0.3s, background-color 0.3s;
  margin-top: 2rem;
  min-width: ${(props: Props) => (props.fullwidth ? '100%' : '15rem')};
  text-align: center;

  &:hover {
    color: ${({theme}) => theme.color.white};
    background-color: ${({theme}) => theme.color.orange};
    cursor: pointer;
  }

  ${props => {
    if (props.severity === 'inverse') {
      return ButtonInverse;
    }
  }};

  ${props => {
    if (props.disabled) {
      return ButtonDisabled;
    }
  }};

  ${props => {
    if (props.size == 'small') {
      return ButtonSmall;
    }
  }};

  ${props => {
    if (props.loading) {
      return ButtonLoading;
    }
  }};

  ${props => {
    if (props.unstyled) {
      return ButtonUnstyled;
    }
  }};
`;

const ButtonLoading = css`
  color: transparent;
  background-image: url(${Loader});
  background-position: center;
  background-repeat: no-repeat;

  &:hover {
    color: transparent;
  }
`;

const ButtonInverse = css`
  background-color: ${({theme}) => theme.color.orange};
  color: ${({theme}) => theme.color.white};

  &:hover {
    background-color: ${({theme}) => theme.color.darkOrange};
    border-color: ${({theme}) => theme.color.darkOrange};
  }
`;

const ButtonDisabled = css`
  background-color: ${({theme}) => theme.color.gray};
  color: ${({theme}) => theme.color.white};
  border-color: ${({theme}) => theme.color.gray};
  cursor: not-allowed;
  pointer-events: none;

  &:hover {
    background-color: ${({theme}) => theme.color.gray};
    border-color: ${({theme}) => theme.color.gray};
    cursor: not-allowed;
  }
`;

const ButtonUnstyled = css`
  background-color: transparent;
  color: ${({theme}) => theme.color.darkGray};
  border-color: transparent;
  min-width: initial;

  &:hover {
    background-color: transparent;
    border-color: transparent;
    color: ${({theme}) => theme.color.darkBlue};
  }
`;

const ButtonSmall = css`
  font-size: 1.4rem;
  padding: 0.8rem 1.4rem 0.7rem;
  margin-top: 0;
  min-width: unset;
`;

export const AnchorButton = styled.a`
  ${ButtonBase};
`;

export const LinkButton = styled(Link)<Props>`
  ${ButtonBase};
`;

export const RegularButton = styled.button`
  ${ButtonBase};
`;
