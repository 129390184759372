import {css} from 'styled-components';

export const Gradient = {
  orange: css`
    background: linear-gradient(
      to right,
      ${({theme}) => theme.color.orange},
      #bf3405
    );
  `,
  blue: css`
    background: linear-gradient(
      to right,
      ${({theme}) => theme.color.lightBlue},
      ${({theme}) => theme.color.darkBlue}
    );
  `,
};
