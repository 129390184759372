import {NavLink} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {media} from '../../../../../mixins';

export const HeaderWrapper = styled.header`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  font-size: 1.8rem;
  grid-area: header;
  justify-content: space-between;

  img {
    margin-top: 0.6rem;
    width: 8.5rem;
  }
`;

export const Nav = styled.nav`
  align-items: center;
  display: flex;
  justify-content: space-between;
  text-align: right;

  ${media.tablet`
    background: ${({theme}) => theme.color.white};
    display: ${({active}) => (active ? 'flex' : 'none')};
    position: absolute;
    padding: 2rem 3rem;
    text-align: left;
    top: ${({theme}) => theme.header.height};
    width: 100%;
    z-index: 10;
  `};
`;

export const NavToggle = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: none;
  line-height: 1;

  &:hover {
    color: ${({theme}) => theme.color.orange};
  }

  ${media.tablet`
    display: block;
    margin-right: 3rem;
    padding: 0;
  `}
`;

export const Logo = styled.span`
  ${media.tablet`
    margin-left: 3rem;
  `};
`;

const NavList = styled.ul`
  margin: 0;
  padding: 0;
  position: relative;

  li {
    display: inline-block;
    font-weight: 700;
    margin: 0 1rem;

    &:last-of-type {
      margin-right: 0;
    }
    &:first-of-type {
      margin-left: 0;
    }
  }

  .button {
    color: ${props => props.theme.color.white};
    background: ${props => props.theme.color.orange};
    padding: 0.4rem 1rem 0.3rem;

    &:hover,
    &.active {
      background: ${props => props.theme.color.darkOrange};
      color: ${props => props.theme.color.white};

      &:hover {
        color: ${props => props.theme.color.white};
      }

      &:after {
        display: none;
      }
    }
  }

  ${media.tablet`
    li {
      display: block;
      margin: 0 0 .7rem;
    }
  `};
`;

export const MainNav = styled(NavList)`
  margin-right: 2rem;
`;

export const UserNav = styled(NavList)`
  border-left: 0.1rem solid ${({theme}) => theme.color.gray};
  margin-right: 5rem;
  padding-left: 2rem;

  ${media.tablet`
    flex: 0 1 auto;
    margin-right: 0;
  `}
`;

const Link = css`
  color: ${props => props.theme.color.black};
  display: inline-block;
  position: relative;
  text-decoration: none;

  &.active:after,
  &:hover:after {
    background-color: ${props => props.theme.color.orange};
    bottom: -1.4rem;
    content: '';
    display: block;
    height: 0.4rem;
    position: absolute;
    width: 100%;
    z-index: 500;

    ${media.tablet`
      bottom: -.3rem;
    `}
  }

  &.active {
    color: ${props => props.theme.color.orange};

    &:hover {
      color: inherit;
    }
  }

  &:hover {
    color: ${props => props.theme.color.darkGray};

    &:after {
      background-color: ${({theme}) => theme.color.darkGray};
    }
  }
`;

export const CartLink = styled.span`
  position: relative;
`;

export const CartCounter = styled.span`
  align-items: center;
  background: ${({theme}) => theme.color.orange};
  border-radius: 50%;
  color: ${({theme}) => theme.color.white};
  display: flex;
  font-size: 1.2rem;
  font-weight: mnormal;
  height: 1.7rem;
  justify-content: center;
  position: absolute;
  right: -1.8rem;
  top: -0.8rem;
  width: 1.7rem;
`;

export const InternalLink = styled(NavLink)`
  ${Link}
`;

export const ExternalLink = styled.a`
  ${Link}
`;
