import * as Types from '../../../gql/types';

import {gql} from '@apollo/client';
import {UserFragmentDoc} from '../../../gql/fragments/user';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type UserQueryVariables = Types.Exact<{[key: string]: never}>;

export type UserQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    careOf?: string | null;
    city?: string | null;
    country?: string | null;
    email?: string | null;
    emailConfirmed: boolean;
    emailConfirmationPending: boolean;
    firstname?: string | null;
    lastname?: string | null;
    name?: string | null;
    state?: string | null;
    street?: string | null;
    unconfirmedEmail?: string | null;
    units: Types.Unit;
    username?: string | null;
    zip?: string | null;
    cart: {__typename?: 'Cart'; count: number};
  };
};

export const UserDocument = gql`
  query user {
    me {
      id
      ...user
      cart {
        count
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options
  );
}
export function useUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserQuery,
    UserQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options
  );
}
export function useUserSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    UserQuery,
    UserQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return ApolloReactHooks.useSuspenseQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options
  );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<
  typeof useUserSuspenseQuery
>;
export type UserQueryResult = ApolloReactCommon.QueryResult<
  UserQuery,
  UserQueryVariables
>;
