import theme from './theme';
import {css} from 'styled-components';

export function contentHeight() {
  return `calc(100vh - ${theme.header.height});`;
}

export const media = {
  phone: (...args: []) => css`
    @media (max-width: ${props => props.theme.media.phone}) {
      // @ts-ignore
      ${css(...args)};
    }
  `,
  tablet: (...args: []) => css`
    @media (max-width: ${props => props.theme.media.tablet}) {
      // @ts-ignore
      ${css(...args)};
    }
  `,
};
