import React, {ReactNode} from 'react';

import {Box} from '../Box';
import {Button} from '../Button';
import {Heading} from '../Heading';
import {Icon} from '../Icon';
import {Action} from '../types';
import {
  Buttons,
  CloseButton,
  Content,
  Header,
  ModalContainer,
  Overlay,
} from './styles/Modal';

interface Props {
  children: ReactNode;
  primaryAction?: Action;
  secondaryAction?: Action;
  title: string;
  onClose?(): void;
}

export function Modal({
  children,
  primaryAction,
  secondaryAction,
  title,
  onClose,
}: Props) {
  const primaryActionMarkup = primaryAction && (
    <Button
      onClick={primaryAction.onAction}
      severity="inverse"
      disabled={primaryAction.disabled}
    >
      {primaryAction.label}
    </Button>
  );

  const secondaryActionMarkup = secondaryAction ? (
    <Button
      onClick={secondaryAction.onAction}
      disabled={secondaryAction.disabled}
    >
      {secondaryAction.label}
    </Button>
  ) : null;

  const closeButtonMarkup = onClose ? (
    <CloseButton onClick={onClose}>
      <Icon icon="close" />
    </CloseButton>
  ) : null;

  return (
    <Overlay>
      <ModalContainer>
        <Box>
          <Header>
            <Heading>{title}</Heading>
            {closeButtonMarkup}
          </Header>
          <Content>{children}</Content>
          <Buttons>
            {secondaryActionMarkup}
            {primaryActionMarkup}
          </Buttons>
        </Box>
      </ModalContainer>
    </Overlay>
  );
}
