import styled from 'styled-components';

export const StyledRadioButtons = styled.ul`
  margin: 1.6rem 0 0;
  padding: 0;
  list-style: none;

  input[type='radio'] {
    opacity: 0;
    position: absolute;
    left: 0.75em;
    top: 0.75em;
  }

  label {
    align-items: center;
    cursor: pointer;
    display: flex;
    line-height: 1.8;
  }

  .box {
    border: 0.2rem solid ${({theme}) => theme.color.orange};
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    margin-right: 0.8rem;
  }

  .selected {
    background-color: ${({theme}) => theme.color.orange};
    border-radius: inherit;
    height: 100%;
    width: 100%;
    transform: scale(0);
    transition: transform 0.1s ease-in;
  }

  input[type='radio']:checked ~ .box .selected {
    transform: scale(0.6);
    transition: transform 0.2s ease-out;
  }
`;
