import React, {useState} from 'react';

import {FormInput, FormFields, Modal} from 'components/ultra';
import {isValidEmail} from 'utilities/email';
import {useUser} from 'utilities/user';

import {useCompleteSignupMutation} from './graphql/CompleteSignup';

export function CompleteSignup() {
  const user = useUser();
  const [email, setEmail] = useState('');
  const [saveUser, {loading}] = useCompleteSignupMutation();

  if (!user || isValidEmail(user.email)) {
    return null;
  }

  function handleSaveUser() {
    saveUser({variables: {user: {email}}});
  }

  const pending = user.emailConfirmationPending;
  const primaryAction = !pending
    ? {
        disabled: loading || !isValidEmail(email),
        label: 'Confirm',
        onAction: handleSaveUser,
      }
    : undefined;

  const content = pending ? (
    <p>
      Thank you for confirming your email address. Click the link in the email
      we just sent to start using Spurtli.
    </p>
  ) : (
    <>
      <p>Confirm your email address so you can start using Spurtli. 🚀</p>
      <FormFields>
        <FormInput
          value={email}
          onChange={evt => setEmail(evt.currentTarget.value)}
          type="email"
          label="Email"
          fullwidth
        />
      </FormFields>
    </>
  );

  return (
    <Modal title="Complete your registration" primaryAction={primaryAction}>
      {content}
    </Modal>
  );
}
