export default {
  media: {
    phone: '599px',
    tablet: '959px',
    laptop: '1279px',
    desktop: '1919px',
  },
  color: {
    primary: '#47717a',
    secondary: '#fc4c02',
    white: '#ffffff',
    offWhite: '#f8f8f8',
    orange: '#e9501c',
    darkOrange: '#6b2710',
    lightBlue: '#7ab1bc',
    darkBlue: '#48727b',
    darkerBlue: '#395b61',
    lightGray: '#f4f6f8',
    gray: '#cccccc',
    darkGray: '#454F5B',
    black: '#222326',
    strava: '#f64a21',
  },
  container: {
    md: '60px',
    lg: '120px',
  },
  header: {
    height: '5rem',
  },
  sidebar: {
    collapsed: '6rem',
    expanded: '30rem',
  },
};
