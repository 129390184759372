import React, {FormEvent, ReactNode} from 'react';
import {AnchorButton, LinkButton, RegularButton} from './styles/Button';

export interface Props {
  children: ReactNode;
  disabled?: boolean;
  fullwidth?: boolean;
  href?: string;
  loading?: boolean;
  severity?: 'inverse';
  size?: string;
  to?: string;
  type?: string;
  unstyled?: boolean;
  onClick?(evt: FormEvent<HTMLInputElement> | Event): void;
}

export function Button({children, href, to, ...props}: Props) {
  if (to) {
    return (
      <LinkButton to={to} {...props}>
        {children}
      </LinkButton>
    );
  }
  if (href) {
    return (
      <AnchorButton href={href} {...props}>
        {children}
      </AnchorButton>
    );
  }
  return <RegularButton {...props}>{children}</RegularButton>;
}
