import styled from 'styled-components';
import {
  Tab as ReactTab,
  Tabs as ReactTabs,
  TabList as ReactTabList,
  TabPanel as ReactTabPanel,
} from 'react-tabs';

export const Tabs = styled(ReactTabs)``;

export const TabList = styled(ReactTabList)`
  padding: 0;
`;

export const Tab = styled(ReactTab).attrs(() => ({
  selectedClassName: 'selected',
  disabledClassName: 'disabled',
}))`
  display: inline-block;
  border-bottom: 0.3rem solid ${({theme}) => theme.color.gray};
  margin-right: 2.5rem;
  cursor: pointer;
  font-weight: 700;

  &:last-of-type {
    margin-right: 0;
  }

  &.selected,
  &:hover {
    border-color: ${({theme}) => theme.color.orange};
  }
`;

export const TabPanel = styled(ReactTabPanel)`
  margin-top: 5rem;
  position: relative;
`;
