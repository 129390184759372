import React from 'react';
import loadable from '@loadable/component';
import {Helmet} from 'react-helmet';
import {Redirect, Route, Switch} from 'react-router';

import {useProtection} from 'utilities/auth';
import {useReactRouter} from 'utilities/react-router';
import {UserProvider} from 'utilities/user';

import {GlobalStyle} from '../../global-styles';
import {CompleteSignup, Header} from './components';
import {useUserQuery} from './graphql/User';
import {Layout} from './styles';

const Cart = loadable(
  () => import(/* webpackChunkName: "cart" */ 'containers/Cart')
);
const Checkout = loadable(
  () => import(/* webpackChunkName: "checkout" */ 'containers/Checkout')
);
const Contact = loadable(
  () => import(/* webpackChunkName: "contact" */ 'containers/Contact')
);
const Dashboard = loadable(
  () => import(/* webpackChunkName: "dashboard" */ 'containers/Dashboard')
);
const Editor = loadable(
  () => import(/* webpackChunkName: "editor" */ 'containers/Editor')
);
const EmailConfirmation = loadable(
  () => import(/* webpackChunkName: "sign-up" */ 'containers/EmailConfirmation')
);
const Help = loadable(
  () => import(/* webpackChunkName: "help" */ 'containers/Help')
);
const Home = loadable(
  () => import(/* webpackChunkName: "home" */ 'containers/Home')
);
const Import = loadable(
  () => import(/* webpackChunkName: "import" */ 'containers/Import')
);
const NotFound = loadable(
  () => import(/* webpackChunkName: "not-found" */ 'containers/NotFound')
);
const Press = loadable(
  () => import(/* webpackChunkName: "press" */ 'containers/Press')
);
const PrivacyPolicy = loadable(
  () =>
    import(/* webpackChunkName: "privacy-policy" */ 'containers/PrivacyPolicy')
);
const Terms = loadable(
  () => import(/* webpackChunkName: "terms" */ 'containers/Terms')
);
const SignUp = loadable(
  () => import(/* webpackChunkName: "sign-up" */ 'containers/SignUp')
);
const Experiment = loadable(
  () => import(/* webpackChunkName: "experiment" */ 'containers/Experiment')
);

interface User {
  id?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  emailConfirmed?: boolean;
  emailConfirmationPending?: boolean;
  username?: string;
  state?: string;
  careOf?: string;
}

export function App() {
  const {location} = useReactRouter();
  const {isAuthenticated} = useProtection();
  const {data} = useUserQuery({
    skip: !isAuthenticated,
  });

  const {__typename, cart = null, ...me} = data?.me || {};
  const user = me.firstname ? me : null;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Spurtli: Your Fitness Activities as Art</title>
        <link rel="canonical" href="https://www.spurtli.com" />
      </Helmet>
      <UserProvider user={user}>
        <Layout sidebarExpand={location.pathname.match(/^\/poster/) != null}>
          <Header cart={cart} />
          <Switch location={location}>
            <Redirect exact path="/" to="/about" />
            <Route path="/poster/:id" component={Editor} />
            <Redirect exact from="/poster" to="/poster/new" />
            <Route path="/import" component={Import} />
            <Route path="/dashboard/:subsection?" component={Dashboard} />
            <Route path="/cart" component={Cart} />
            <Route path="/checkout" component={Checkout} />
            <Route path="/contact" component={Contact} />
            <Route
              path="/email-confirmation/:state/:message?"
              component={EmailConfirmation}
            />
            <Route path="/:page(about|pricing)" component={Home} />
            <Route path="/help" component={Help} />
            <Route path="/press" component={Press} />
            <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="/sign-up" component={SignUp} />
            <Route path="/terms" component={Terms} />
            <Route
              path="/21c65dc3c9dd6efd4649445abe949011"
              component={Experiment}
            />
            <Route path="" component={NotFound} />
          </Switch>
          <CompleteSignup />
        </Layout>
        <GlobalStyle />
      </UserProvider>
    </>
  );
}
