import styled, {keyframes} from 'styled-components';

const slideInFromTop = keyframes`
  0% {
    transform: translateY(-100%)
  }
  100% {
    transform: translateY(0)
  }
`;

export const StyledBanner = styled.div`
  min-height: 6rem;
  width: 100%;
  padding: 2rem;
  animation: ${slideInFromTop} 0.3s ease-out;
  position: relative;
  background: ${({theme}) => theme.color.white};
  border-top: 0.3rem solid ${({theme}) => theme.color.orange};
  border-radius: 0.3rem;
  display: block;

  & + & {
    margin-top: 2rem;
  }

  &.error {
    border-color: #bf3405;
  }
`;

export const Message = styled.div`
  margin: 0.8rem 0 0;
`;

export const Actions = styled.div`
  margin: 0.8rem 0 0;
`;

export const CloseButton = styled.button`
  background: none;
  display: block;
  border: none;
  font-size: 2.8rem;
  /* color: ${({theme}) => theme.color.orange}; */
  padding: 0;
  cursor: pointer;
  backface-visibility: hidden;
  position: absolute;
  top: 1.2rem;
  right: 1.6rem;

  svg {
    transition: all 0.2s ease-out;

    &:hover {
      color: ${({theme}) => theme.color.orange};
      transform: scale(1.1);
    }
  }
`;
