import React, {ReactNode} from 'react';
import {css} from 'styled-components';
import {Gradient} from '../css';

import {H1, H2, H3, H4} from './styles/Heading';

export const textGradientOrange = css`
  ${Gradient.orange};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

interface Props {
  children: ReactNode;
  className?: string;
  element?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
  gradient?: boolean;
}

export function Heading({element, children, ...otherProps}: Props) {
  switch (element) {
    case 'h1':
      return <H1 {...otherProps}>{children}</H1>;
    case 'h3':
      return <H3 {...otherProps}>{children}</H3>;
    case 'h4':
      return <H4 {...otherProps}>{children}</H4>;
    case 'h5':
      return <h5>{children}</h5>;
    case 'h6':
      return <h6>{children}</h6>;
    case 'p':
      return <p>{children}</p>;
    default:
      return <H2 {...otherProps}>{children}</H2>;
  }
}
