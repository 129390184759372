import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import {ApolloProvider} from '@apollo/client';
import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import {loadableReady} from '@loadable/component';
// @ts-ignore

import {AuthProvider} from 'utilities/auth';

import theme from './theme';
import './global-styles';

import {client} from 'gql';
import {service as auth} from 'utilities/auth';

moment.locale('en-US');

const MOUNT_NODE = document.getElementById('app');

function render() {
  const App = require('containers/App').default;

  const content = (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <AuthProvider service={auth}>
            <App />
          </AuthProvider>
        </ThemeProvider>
      </ApolloProvider>
    </BrowserRouter>
  );

  ReactDOM.hydrate(content, MOUNT_NODE);
}

if (module.hot) {
  module.hot.accept(['containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}

loadableReady(() => {
  render();
});
